import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { GroupPageLayout } from '../components/layouts'

class PagesGroupTemplatePTP extends React.Component {
    render() {
        const group = get(this.props, 'data.contentfulPageGroupPtp')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemapPtp.edges', [])
        sitemap = sitemap.map((link) => link && link.node)
        return <GroupPageLayout group={group} sitemap={sitemap} className="ptp-root" />
    }
}

export default PagesGroupTemplatePTP

export const pageQuery = graphql`
    query PagesGroupPTPBySlug($slug: String!) {
        contentfulPageGroupPtp(slug: { eq: $slug }) {
            __typename
            title
            userSection
            orderOption
            slug
            goToPage
            headerImage {
                file {
                    url
                }
                title
            }
            headerVideo
            headerImageFullWidth
            body {
                childContentfulRichText {
                    internal {
                        content
                    }
                    html
                }
            }
            image {
                title
                file {
                    url
                }
            }
            groups {
                slug
                title
                contentful_id
                userSection
            }
            summary {
                summary
            }
            pagegenericptp {
                __typename
                contentful_id
                title
                slug
                summary {
                    summary
                }
                goToPage
                userSection
                createdAt
            }
            pagegroupptp {
                __typename
                title
                slug
                summary {
                    summary
                }
                goToPage
                userSection
                createdAt
            }
            pagewidgetsptp {
                __typename
                title
                slug
                userSection
                goToPage
                createdAt
            }
            metaDescription {
                metaDescription
            }
            orderedList {
                ... on ContentfulPageGenericPtp {
                    __typename
                    contentful_id
                    title
                    slug
                    summary {
                        summary
                    }
                    goToPage
                    userSection
                    createdAt
                }
                ... on ContentfulPageGroupPtp {
                    __typename
                    title
                    slug
                    summary {
                        summary
                    }
                    goToPage
                    userSection
                    createdAt
                }
                ... on ContentfulPageWidgetsPtp {
                    __typename
                    title
                    slug
                    userSection
                    goToPage
                    createdAt
                }
            }
            sections {
                name
                fullWidth
                widgets {
                    __typename
                    ... on ContentfulPageGroupPtp {
                        contentful_id
                        title
                        slug
                        userSection
                        headerImage {
                            file {
                                url
                            }
                        }
                        image {
                            file {
                                url
                            }
                        }
                        imagePosition
                        groups {
                            __typename
                            title
                            slug
                        }
                        pagegenericptp {
                            __typename
                            title
                            slug
                            userSection
                        }
                        pagegroupptp {
                            __typename
                            title
                            slug
                            userSection
                        }
                        pagewidgetsptp {
                            __typename
                            title
                            slug
                            userSection
                        }
                    }
                    ... on ContentfulWidgetSearchPanelPtp {
                        contentful_id
                        title
                        description {
                            description
                        }
                        bgImage {
                            title
                            file {
                                url
                            }
                        }
                        showCategories
                    }
                    ... on ContentfulWidgetStoryBlockPtp {
                        contentful_id
                        title
                        summary {
                            summary
                        }
                        backgroundImage {
                            title
                            file {
                                url
                            }
                        }
                        textButton
                        externalHyperlink
                        internalHyperlink {
                            __typename
                            ... on ContentfulPageDirectoryOfServicesPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGenericPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGroupPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageWidgetsPtp {
                                title
                                slug
                                userSection
                            }
                        }
                    }
                    ... on ContentfulWidgetHeroPtp {
                        contentful_id
                        title
                        searchText
                        type
                        summary {
                            childContentfulRichText {
                                internal {
                                    content
                                }
                                html
                            }
                        }
                        backgroundImage {
                            title
                            file {
                                url
                            }
                        }
                        featureList {
                            __typename
                            ... on ContentfulWidgetStoryBlockPtp {
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                textButton
                                externalHyperlink
                                internalHyperlink {
                                    __typename
                                    ... on ContentfulPageDirectoryOfServicesPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGenericPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroupPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageWidgetsPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                            }
                            ... on ContentfulWidgetLinkBlockPtp {
                                __typename
                                title
                                slug
                                backgroundImage {
                                    title
                                    file {
                                        url
                                    }
                                }
                                featureLink: link {
                                    __typename
                                    ... on ContentfulPageDirectoryOfServicesPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGenericPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulWidgetLinkPtp {
                                        text
                                        url
                                    }
                                    ... on ContentfulPageWidgetsPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                    ... on ContentfulPageGroupPtp {
                                        title
                                        slug
                                        userSection
                                    }
                                }
                                icon
                            }
                        }
                        link {
                            text
                            url
                        }
                    }
                    ... on ContentfulWidgetLinksPtp {
                        __typename
                        title
                        description {
                            description
                        }
                        backgroundColor
                        textColor
                        imagePosition
                        image {
                            title
                            file {
                                url
                            }
                        }
                        links {
                            __typename
                            ... on ContentfulPageGenericPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulWidgetLinkPtp {
                                text
                                url
                            }
                        }
                    }
                    ... on ContentfulWidgetLinkBlockPtp {
                        __typename
                        title
                        slug
                        summary {
                            summary
                        }
                        backgroundImage {
                            file {
                                url
                            }
                        }
                        buttonText
                        buttonLink: link {
                            __typename
                            ... on ContentfulPageDirectoryOfServicesPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGenericPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulWidgetLinkPtp {
                                text
                                url
                            }
                            ... on ContentfulPageWidgetsPtp {
                                title
                                slug
                                userSection
                            }
                            ... on ContentfulPageGroupPtp {
                                title
                                slug
                                userSection
                            }
                        }
                    }
                    ... on ContentfulWidgetRichTextPtp {
                        contentful_id
                        content {
                            childContentfulRichText {
                                internal {
                                    content
                                }
                                html
                            }
                        }
                    }
                    ... on ContentfulWidgetImpactPtp {
                        id
                        title
                        introduction {
                            introduction
                        }
                        contentful_id
                        items {
                            colour
                            icon {
                                file {
                                    url
                                }
                                description
                                title
                            }
                            text
                            url
                        }
                        link {
                            text
                            url
                        }
                    }
                }
            }
        }
        allContentfulWidgetSitemapPtp(filter: { title: { ne: "Don't delete - sitemap" } }) {
            edges {
                node {
                    contentful_id
                    userSection
                    title
                    slug
                    links {
                        __typename
                        ... on ContentfulPageDirectoryOfServicesPtp {
                            id
                            title
                        }
                        ... on ContentfulPageGenericPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageGroupPtp {
                            slug
                            title
                            userSection
                        }
                        ... on ContentfulPageWidgetsPtp {
                            title
                            slug
                            userSection
                        }
                    }
                }
            }
        }
    }
`
